import React, { useContext } from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { TranslationContext } from "../TranslationContext";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  const handleNavLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { translation } = useContext(TranslationContext);
  const currentYear = new Date().getFullYear(); 


  return (
    <div className="footer">
      <div className="footer-part-horario-wrapper">
        <div className="horarioRB">
          <div className="horarioRestaurant">
            <div className="day">{translation.openingRestaurant}</div>
            <div className="hours">
              <div className="firstLine">
                <div className="day-name">{translation.mondayRestaurant}:</div>
                <div className="hours-text">12:30–22:00</div>
              </div>
              <div className="secondLine">
                <div className="day-name">{translation.viernesRestaurant}:</div>
                <div className="hours-text">12:30–23:00</div>
              </div>
            </div>
          </div>
        
          <div className="horarioBar">
            <div className="day">{translation.openingBar}</div>
            <div className="hours">
            <div className="firstLine">
              <div className="day-name">{translation.mondayRestaurant}:</div>
              <div className="hours-text">9:00–22:00</div>
              </div>
              <div className="secondLine">
              <div className="day-name">{translation.viernesRestaurant}:</div>
              <div className="hours-text">9:00–23:00</div>
              </div>
            </div>
          </div>
         
        </div>
        <div className="horario-martesCerrado">
          <div className="day-name">{translation.thuesday}:</div>
          <div className="hours-text">{translation.closed}</div>
        </div>
      </div>
      <div className="footer-part">
        <h2>{translation.adress}</h2>
        <p className="dirección">
          <a href="https://maps.app.goo.gl/9GHBgxwUUuMTqSaM7">
            <FontAwesomeIcon className="icon" icon={faLocationDot} /> Carretera
            las Nieves, 17, 38700 Santa Cruz de la Palma, Santa Cruz de
            Tenerife, Spain
          </a>
        </p>
      </div>
      <div className="footerDown">
        <div className="explotaciones">EXPLOTACIONES MENDOZA Y RODRÍGUEZ S.L. {currentYear}</div>
        <div>
          <NavLink
            exact
            to="/Policy"
            activeClassName="active"
            className="nav-link-policy"
            onClick={handleNavLinkClick}
          >
            Privacy policy
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Footer;
